import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Button from "@mui/material/Button"
import BreadCrumbs from "../components/bread-crumbs"
import "./help-people.scss"

let Coverflow = null
if (global.window) {
  Coverflow = require("react-coverflow")
}

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

class HelpPeople extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.analytics.page("Help People")
    }
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Help People" />
        <BreadCrumbs
          currentPage={"Help People"}
          breadCrumbsList={breadCrumbsList}
        />
        <div className="request-wrapper">
          <h2 className="heading1">
            Get paid for saving lives and <br />
            killing stigma, on your schedule.
          </h2>
          <p className="parah">
            Treatment for substance use is broken in the United States. We built
            an entirely new incentive and risk mitigation structure to increase
            access for lower severity patients who want help.{" "}
          </p>
          <Button className="blue-gradient-btn" color="primary">
            Request to join
          </Button>
        </div>

        <div className="link-wrapper">
          <h2 className="heading2">
            Joining the Confidant Network is easy as 1-2-3. <br />
            For truly great providers like yourself.
          </h2>
          <div className="link-list">
            <div className="single-link">
              <img
                className="link-img"
                src={require("../assets/images/one.svg")}
                alt="One Icon"
              />
              <h3 className="heading3">Complete Initial Questionnaire</h3>
              <p className="parah">
                Before we spend any time in interviews, you’ll answer a few
                questions about your practice philosophy and licensure.
              </p>
            </div>
            <Button href="#">
              <img
                className="next-icon"
                src={require("../assets/images/next_icon.svg")}
                alt="Next"
              />
            </Button>
            <div className="single-link">
              <img
                className="link-img"
                src={require("../assets/images/two.svg")}
                alt="Two Icon"
              />
              <h3 className="heading3">Schedule Interview Time</h3>
              <p className="parah">
                We’ll schedule a 30 minute interview with members of your care
                team to further evaluate cultural fit.
              </p>
            </div>
            <Button href="#">
              <img
                className="next-icon"
                src={require("../assets/images/next_icon.svg")}
                alt="Next"
              />
            </Button>
            <div className="single-link">
              <img
                className="link-img"
                src={require("../assets/images/three.svg")}
                alt="Three Icon"
              />
              <h3 className="heading3">Begin Working with Patients</h3>
              <p className="parah">
                After you’re trained on the systems, you’ll begin working with
                patients in tandem with other care team members.
              </p>
            </div>
          </div>
        </div>

        <div className="testimonial-wrapper">
          <div className="test-left-side">
            <h2 className="heading2">
              Be recognized for what you are, <br />
              one of the best providers in your area, if not the world.
            </h2>
            <p className="parah">
              We’re only selecting the top providers to be part of the Confidant
              Network. Our vetting process allows you to showcase your previous
              work and treatment philosophy. We’re only selecting the top 5% of
              applicants to help maintain our high standards.
            </p>
            <Button className="blue-gradient-btn">Get Started</Button>
          </div>
          <div className="test-right-side">
            {Coverflow && (
              <Coverflow
                width={490}
                height={350}
                displayQuantityOfSide={2}
                navigation={false}
                enableHeading={false}
                active={2}
              >
                <img
                  src={require("../assets/images/provider_card.png")}
                  alt="first"
                  data-action="#"
                />
                <img
                  src={require("../assets/images/provider_card.png")}
                  alt="second"
                  data-action="#"
                />
                <img
                  src={require("../assets/images/provider_card.png")}
                  alt="third"
                  data-action="#"
                />
              </Coverflow>
            )}

            <div className="quote-box">
              <img
                className="quote-ico"
                src={require("../assets/images/quote_icon.svg")}
                alt="Icon"
              />
              <p className="quote-text">
                I wish we had started using Confidant as soon as my son started
                using heroin - it would’ve saved us.
              </p>
              <p className="author">Hannah Johnson</p>
            </div>
          </div>
        </div>

        <div className="provider-info-wrapper">
          <h2 className="heading1">
            We designed the Confidant Network to give <br /> you the best
            possible experience.{" "}
          </h2>
          <p className="parah">
            After interviewing hundreds of providers, we built the entire
            Confidant Network from the ground up to enhance what people loved
            about working with patients and eliminating what they hate.
          </p>
          <div className="pro-info-row">
            <div className="info-text-section" style={{ textAlign: "right" }}>
              <img
                className="icon-gre"
                src={require("../assets/images/speaker_icon.svg")}
                alt="Speaker Icon"
              />
              <h2 className="heading2">We handle the marketing </h2>
              <p className="parah">
                We combine a team of dedicated SEO experts with on-the-ground
                event coordinators to build awareness of the network in your
                area, both digitally and in-person.
                <br />
                Once you’ve been selected, you’ll be given a Confidant Network
                profile, that you can link to your other business website to
                increase traffic to your site to enhance your online reputation.
              </p>
            </div>
            <div className="info-img-section">
              <img
                className="icon-gre"
                src={require("../assets/images/phone-frame.png")}
                alt="Phone"
              />
            </div>
          </div>

          <div className="pro-info-row">
            <div className="info-img-section">
              <img
                className="icon-gre"
                src={require("../assets/images/phone-frame-2.png")}
                alt="Phone"
              />
            </div>
            <div className="info-text-section" style={{ textAlign: "left" }}>
              <img
                className="icon-gre"
                src={require("../assets/images/calendar_icon.svg")}
                alt="Calendar Icon"
              />
              <h2 className="heading2">We book your appointments</h2>
              <p className="parah">
                Practicing in the Confidant Network is easy. You simply let us
                know your availability for the upcoming month and we’ll book you
                appointments in your available time slots.
                <br />
                Most of our providers start by working a few hours in the
                evenings or on days they have off.
              </p>
            </div>
          </div>

          <div className="pro-info-row">
            <div className="info-text-section" style={{ textAlign: "right" }}>
              <img
                className="icon-gre"
                src={require("../assets/images/grade_icon.svg")}
                alt="Grade Icon"
              />
              <h2 className="heading2">We provide the latest training</h2>
              <p className="parah">
                The science surrounding the affects of substances on our
                behaviors and brain functions are constantly evolving. The care
                pathways used by the Confidant Network were developed by leading
                researchers, advocates, and epidemilogists.
                <br />
                As a member of the Network, you’ll recieve free regular training
                from these experts to keep you up to date on the latest science
                and techniques.
              </p>
            </div>
            <div className="info-img-section">
              <img
                className="icon-gre"
                src={require("../assets/images/phone-frame.png")}
                alt="Phone"
              />
            </div>
          </div>

          <div className="pro-info-row">
            <div className="info-img-section">
              <img
                className="icon-gre"
                src={require("../assets/images/phone-frame-2.png")}
                alt="Phone "
              />
            </div>
            <div className="info-text-section" style={{ textAlign: "left" }}>
              <img
                className="icon-gre"
                src={require("../assets/images/hand_icon.svg")}
                alt="Hand Icon"
              />
              <h2 className="heading2">We handle the documentation</h2>
              <p className="parah">
                Each patient interaction is recorded, transcribed, and
                documented for you. You’ll work with a dedicated member of our
                administrative team to best reflect your practice style and
                thoughts on your interactions with your patients.
                <br />
                Our system has been shown to reduce the administrative burden
                for providers from an average of 25 minutes to 3 minutes per
                visit.
              </p>
            </div>
          </div>

          <div className="pro-info-row" style={{ marginBottom: 80 }}>
            <div className="info-text-section" style={{ textAlign: "right" }}>
              <img
                className="icon-gre"
                src={require("../assets/images/money_icon.svg")}
                alt="Money Icon"
              />
              <h2 className="heading2">We pay you immediately</h2>
              <p className="parah">
                Managing billing and collections on your patient visits is a
                nightmare. When you practice in the Confidant Network, we’ll pay
                you immediately upon signing off on the documentation we create
                for you.
                <br />
                To solve this epidemic, we need all the help we can get and we
                want to make it as easy and rewarding as possible for you to
                help.
              </p>
            </div>
            <div className="info-img-section">
              <img
                className="icon-gre"
                src={require("../assets/images/phone-frame.png")}
                alt="Phone"
              />
            </div>
          </div>
          <Button className="blue-gradient-btn">Request network access</Button>
        </div>

        <div className="guarantee-wrapper">
          <h2 className="heading2">Request network access</h2>
          <h5 className="heading5">
            If we don’t match you with the best patients for you, we’ll pay you
            anyways.
          </h5>
          <p className="parah">
            Our nation is facing a serious epidemic that needs to be solved now.
            We need everyone in the community to help, but just because you want
            to help, doesn’t mean you want to devote your life to it.
            <br />
            That’s why we’ve designed the Confidant Network to allow you to help
            a like-minded group of providers in your spare time from home.
            <br />
            We know even this change can be a big one, so to help incentize the
            best providers to join, we’re going to guarantee 5 hours of paid
            clinical time per month, regardless of whether we book you
            appointments or not.
          </p>
          <Button className="blue-gradient-btn">Request to join</Button>
          <div className="providers">
            <img
              className="providers-img"
              src={require("../assets/images/providers.png")}
              alt="Providers"
            />
            <p className="pro-name">Your Name</p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default HelpPeople
